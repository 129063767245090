

// ------------ A C U E R D O S    I T E ------------
export const dataAcuerdos2025 = [
  {
    id: 1,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "POR EL QUE SE REDECUA DISTRIBUCIÓN DE PRERROGATIVAS A PP.",
    titleAnexo1: "FINANCIAMIENTO PUBLICO ANUAL.",
    titleAnexo2: "FINANCIAMIENTO PUB ACT ESPECÍFICAS.",
  },
  {
    id: 2,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "ACTUALIZACIÓN Y CALENDARIZACIÓN DE MULTAS PP.",
    titleAnexo1: "ACTUALIZACIÓN CALENDARIZACIÓN SANCIONES.",
    titleAnexo2: "CALENDARIZADO DE SANCIONES.",
  },
  {
    id: 3,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ENE",
    nameDoc:
      "MODIFICACIÓN DE DOCUMENTOS BÁSICOS FXMT.",
  },
  {
    id: 4,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "POR EL QUE SE APRUEBA EL PROGRAMA DE IMPARTICIÓN ELABORADO POR LA JUNTA GENERAL EJECUTIVA.",
    titleAnexo1: "PROGRAMA DE IMPARTICIÓN DE SEMINARIOS, CONFERENCIAS, COLOQUIOS, DIPLOMADOS, MESAS REDONDAS, DIVULGACIÓN DE TEXTOS, PUBLICACIONES DE RESULTADOS DE INVESTIGACIÓN Y PRESENTACIÓN DE LIBROS 2025.",
  },
  {
    id: 5,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ENE",
    nameDoc:
      "DENTRO DEL POS CON NÚMERO DE EXPEDIENTE CQD/Q/IML/CG/076/2024.",
  },
  {
    id: 6,
    typeDoc: " ACUERDO ",
    monthDoc: "FEB",
    nameDoc:
      "POR EL QUE SE REFORMA EL REGLAMENTO DE SESIONES DEL CONSEJO GENERAL DEL ITE.",
      titleAnexo1: "REGLAMENTO DE SESIONES DEL CG DEL ITE",
  },
  {
    id: 7,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL QUE SE APRUEBA EL PROYECTO DE PRESUPUESTO PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
      titleAnexo1: "AMPLIACION PRESUPUESTAL",
  },
  {
    id: 8,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL SE APRUEBA EL CALENDARIO ELECTORAL LEGAL PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
      titleAnexo1: "CALENDARIO ELECTORAL LEGAL  ",
  },
  {
    id: 9,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL QUE SE APRUEBAN LOS LINEAMIENTOS DE DESIGNACIÓN DE PERSONAS INTEGRANTES DE CD PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024 2025.",
      titleAnexo1: "MANIFESTACIÓN DE INTENCIÓN",
      titleAnexo2: "SOLICITUD DE REGISTRO",
      titleAnexo3: "RESUMEN CURRICULAR",
      titleAnexo4: "DECLARACIÓN BAJO PROTESTA DE DECIR VERDAD",
      titleAnexo5: "DECLARACIÓN ART 38",
      titleAnexo6: "LINEAMIENTOS CD PELEPJ",
      titleAnexo7: "FE DE ERRATAS",
  },
  {
    id: 10,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL QUE SE APRUEBA LA ESTRATEGIA DE RECLUTAMIENTO DE SEL Y CAEL PELEPJ 2024-2025.",
    titleAnexo1: "ESTRATEGIA RECLUTAMIENTO DE SEL Y CAEL PELEPJ",
  },
  {
    id: 11,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL QUE SE DETERMINAN LOS CRITERIOS PARA GARANTIZAR EL PRINCIPIO CONSTITUCIONAL DE PARIDAD DE GÉNERO EN EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
  },
  {
    id: 12,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "FEB",
    nameDoc: "RELATIVA AL DICTADO DE MEDIDAS CAUTELARES, EMITIDA POR EL CONSEJO GENERAL DEL ITE, DENTRO DEL PROCEDIMIENTO ESPECIAL SANCIONADOR CQD/CA/CG/001/2025.",
  },
  {
    id: 13,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "FEB",
    nameDoc: "RELATIVA AL DICTADO DE MEDIDAS CAUTELARES, EMITIDA POR EL CONSEJO GENERAL DEL ITE, DENTRO DEL PROCEDIMIENTO ESPECIAL SANCIONADOR CQD/CA/CG/002/2025.",
  },
  {
    id: 14,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "FEB",
    nameDoc: "DICTADA POR EL CONSEJO GENERAL DEL ITE, DENTRO DEL PROCEDIMIENTO ORDINARIO SANCIONADOR CQD/Q/FXMT/CG/077/2024.",
  },
  {
    id: 15,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL QUE SE APRUEBA EL DICTAMEN GENERAL DE RESULTADOS DE LA EVALUACIÓN DESEMPEÑO SPEN.",
    titleAnexo1: "DICTAMEN GENERAL DE RESULTADOS DE LA EVALUACIÓN DEL DESEMPEÑO DEL PERSONAL DEL SPEN DEL SISTEMA DE LOS ORGANISMOS PÚBLICOS LOCALES ELECTORALES.",

  },
  {
    id: 16,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL QUE SE APRUEBA LA METODOLOGíA, PARA LA DIFUSIÓN Y  PROMOCIÓN DE LA PARTICIPACIÓN CIUDADANA PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024 2025.",
    titleAnexo1: "METODOLOGÍA PARA LA DIFUSIÓN Y PROMOCIÓN DE LA PARTICIPACIÓN CIUDADANA EN EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024 2025.",
  },
  {
    id: 17,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL QUE SE APRUEBA EL PROGRAMA OPERATIVO DE LA RED DE MUJERES JUZGADORAS CANDIDATAS Y ELECTAS PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo1: "PROGRAMA OPERATIVO DE LA RED DE MUJERES JUZGADORAS  CANDIDATAS Y ELECTAS PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo2: "FE DE ERRATAS ACUERDO ITE-CG 17-2025",

  },
  {
    id: 18,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBAN LOS LINEAMIENTOS POR EL CONGRESO DEL ESTADO DE TLAXCALA DE LAS PERSONAS CANDIDATAS A JUZGADORAS QUE SE POSTULAN PARA LOS CARGOS DE MAGISTRADAS Y MAGISTRADOS, JUEZAS Y JUECES DEL PODER JUDICIAL DEL ESTADO DE TLAXCALA, PARA EL PELE 2024 2025.",
    titleAnexo1: "VOTO PARTICULAR",
    titleAnexo2: "VOTO CONCURRENTE",
    titleAnexo3: "LINEAMIENTOS REVISIÓN Y TRATAMIENTO EXPEDIENTES",
    titleAnexo4: "ACUSE DE RECIBO EXP DIGITAL PEEPJL",



  },
  {
    id: 19,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA LA CONTRATACIÓN DE UN TERCERO ESPECIALIZADO PARA EL DISEÑO, DESARROLLO, IMPLEMENTACIÓN Y OPERACIÓN DEL SISTEMA INFORMÁTICO DE CÓMPUTOS PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
  },
  {
    id: 20,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBAN LOS LINEAMIENTOS PARA FOROS DE DEBATE ORGANIZADOS POR EL ITE Y, DE LAS ENTREVISTAS Y FOROS DE DEBATE PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo1: "LINEAMIENTOS PARA FOROS DE DEBATE ORGANIZADOS POR EL ITE",
  },
  {
    id: 21,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBAN LAS METODOLOGÍAS PARA LOS DISTINTOS MONITOREOS Y EL CATÁLOGO DE MEDIOS DE COMUNICACIÓN IMPRESOS, DIGITALES Y DE RADIO Y TELEVISIÓN A MONITOREAR EN EL PELEPJ 2024-2025.",
    titleAnexo1: "METODOLOGÍA MONITOREO CONTRATACIÓN DE ESPACIOS PUBLICITARIOS",
    titleAnexo2: "METODOLOGÍA MONITOREO PERSPECTIVA DE GÉNERO",
    titleAnexo3: "CATÁLOGO DE MEDIOS DE COMUNICACIÓN",
  },
  {
    id: 22,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBAN LOS LINEAMIENTOS DE ASIGNACIÓN DE CARGOS PELEPJ 2024-2025.",
    titleAnexo1: "LINEAMIENTOS ASIGNACIÓN CARGOS PELEPJ 2024-2025.",
  },
  {
    id: 23,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL SE APRUEBAN LOS DICTÁMENES QUE EMITEN LA COMISIÓN DE ORGANIZACIÓN ELECTORAL, CAPACITACIÓN, EDUCACIÓN CÍVICA Y LA COMISIÓN TEMPORAL DE DOCUMENTACIÓN ELECTORAL PARA EL PROCESO ELECTORAL EXTRAORDINARIO 2024-2025.",
    titleAnexo1: "DICTAMEN MATERIALES PELEPJ 2024-2025.",
    titleAnexo2: "DICTAMEN POR EL QUE SE APRUEBA EL INFORME SOBRE EL DISEÑO Y MODELOS DEFINITIVOS DE DOCUMENTACIÓN ELECTORAL PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo3: "INFORME DE DOCUMENTACIÓN Y MATERIALES PEEPJ 2025.",
  },
  {
    id: 24,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBAN LOS LINEAMIENTOS PARA EL USO DEL SISTEMA CANDIDATAS Y CANDIDATOS, “CONÓCELES” Y DE SU VERSIÓN EJECUTIVA DEL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo1: "LINEAMIENTOS PARA EL USO DEL SISTEMA CANDIDATAS Y CANDIDATOS, “CONÓCELES”.",
  },
  {
    id: 25,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE DA RESPUESTA AL ESCRITO PRESENTADO POR LA REPRESENTANTE PROPIETARIA DEL PARTIDO DEL TRABAJO, ANTE ESTE CONSEJO GENERAL.",
  },
  {
    id: 26,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE DECLARA LA PROCEDENCIA CONSTITUCIONAL Y LEGAL, RESPECTO DE LA MODIFICACIÓN A LOS DOCUMENTOS BÁSICOS DEL PARTIDO POLÍTICO LOCAL REDES SOCIALES PROGRESISTAS TLAXCALA.",
  },
  {
    id: 27,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA LA CONVOCATORIA PERMANENTE PARA PARTICIPAR COMO CAPACITADOR/A ASISTENTE ELECTORAL LOCAL EN EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.  ",
    titleAnexo1: "CONVOCATORIA CAEL PELEPJ 2024-2025",
  
  },
  // {
  //   id: 28,
  //   typeDoc: "ACUERDO",
  //   monthDoc: "MAR",
  //   nameDoc: "POR EL QUE SE READECÚA EL PRESUPUESTO DE EGRESOS DEL EJERCICIO FISCAL DOS MIL VEINTICINCO DE ESTE INSTITUTO.",
  //   titleAnexo1: "AMPLIACIÓN PRESUPUESTAL 2025",
  
  // },
  {
    id: 29,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA LA INTEGRACIÓN DE LOS CONSEJOS DISTRITALES ELECTORALES PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo1: "INTEGRACIÓN DE LOS CONSEJOS DISTRITALES PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo2: "LISTA DE RESERVA DE MUJERES PARA LOS CONSEJOS DISTRITALES ELECTORALES PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
  
  },



  {
    id: 30,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE CALENDARIZA LA RETENCIÓN DE LAS MULTAS PREVISTAS EN LAS RESOLUCIONES INE/CG302/2021, INE/CG1401/2021, INE/CG258/2024 E INE/CG2459/2024.",
    titleAnexo1: "CALENDARIZADO DE SANCIONES.",
  
  },
  {
    id: 31,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE REFORMA EL REGLAMENTO DE LOS CONSEJOS DISTRITALES Y MUNICIPALES ELECTORALES DEL INSTITUTO TLAXCALTECA DE ELECCIONES.",
    titleAnexo1: "REGLAMENTO DE LOS CONSEJOS DISTRITALES Y MUNICIPALES ELECTORALES DEL ITE.",  
  },
  {
    id: 32,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA EL PROTOCOLO PARA LA DETECCIÓN RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES, DOCUMENTACIÓN Y MATERIALES ELECTORALES RECIBIDOS EN UN ÓRGANO ELECTORAL DISTINTO AL COMPETENTE, PARA EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025. ",
    titleAnexo1: "PROTOCOLO ENTREGA DE PAQUETES INE-ITE.",
  
  },
  {
    id: 33,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA LA DESIGNACIÓN DE LOS LUGARES QUE OCUPARÁN LAS BODEGAS ELECTORALES, EL PERSONAL AUTORIZADO, ASÍ COMO A LA PERSONA RESPONSABLE DE LLEVAR EL CONTROL PRECISO SOBRE LA ASIGNACIÓN DE FOLIOS DE LAS BOLETAS ELECTORALES DEL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo1: "ESPACIOS DE BODEGAS.",
    titleAnexo2: "PERSONAL AUTORIZADO PARA EL ACCESO A LAS BODEGAS ELECTORALES.",

  },
  {
    id: 34,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE DETERMINAN LOS TOPES MÁXIMOS DE GASTOS PERSONALES DE CAMPAÑA DE LAS PERSONAS CANDIDATAS EN EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.", 
  },
  {
    id: 35,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL CUAL SE APRUEBAN LAS REGLAS GENERALES PARA EL TRAMITE DE LOS PROCEDIMIENTOS SANCIONADORES EN EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
    titleAnexo1: "REGLAS GENERALES PARA EL TRAMITE DE LOS PROCEDIMIENTOS SANCIONADORES EN EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",
  
  },
  {
    id: 36,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBAN LAS MEDIDAS DE SEGURIDAD QUE CONTENDRÁN LAS BOLETAS ELECTORALES, ASÍ COMO LA DETERMINACIÓN DE LA FECHA LÍMITE EN LA QUE SE PODRÁN MODIFICAR LAS BOLETAS ELECTORALES A UTILIZARSE EN LA JORNADA ELECTORAL DEL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",  
  },
  {
    id: 37,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA LA SELECCIÓN DE TEMAS PARA LOS FOROS DE DEBATES ORGANIZADOS POR EL ITE EN EL PROCESO ELECTORAL LOCAL EXTRAORDINARIO 2024-2025.",  
  },
  {
    id: 38,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "MAR",
    nameDoc: "PROCEDIMIENTO ORDINARIO SANCIONADOR CQD-Q-PVEM-CG-011-2024",  
  },
];

// ------------ A C U E R D O S    I N E ------------
export const dataAcuerdosINE2025 = [
 {
    id: 1,
    typeDoc: "ACUERDO",
    numDoc: "INE/CG325/2025",
    nameDoc:     
     "ACUERDO DEL CONSEJO GENERAL DEL INE POR EL QUE SE APRUEBAN LAS CONVOCATORIAS PARA LA SELECCIÓN Y DESIGNACIÓN DE LAS PRESIDENCIAS DE LOS ORGANISMOS PÚBLICOS LOCALES,ASÍ COMO DE LAS CONSEJERÍAS ELECTORALES, Y SE ACTUALIZAN LOS LINEAMIENTOS PARA LA APLICACIÓN Y EVALUACIÓN DEL ENSAYO QUE PRESENTARÁN LAS PERSONAS ASPIRANTES QUE OBTENGAN LA MEJOR PUNTUACIÓN EN EL EXAMEN DE CONOCIMIENTOS, APROBADOS MEDIANTE EL ACUERDO INE/CG1417/2021.",
     titleAnexoPDF1: "LINEAMIENTOS PARA LA APLICACIÓN Y EVALUACIÓN DEL ENSAYO QUE PRESENTARÁN LAS Y LOS ASPIRANTES EN LOS PROCESOS DE SELECCIÓN ELECTORALES DE LOS ORGANISMOS PÚBLICOS LOCALES.",
    
    }, 

];
